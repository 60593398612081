import { action, computed, makeObservable, observable } from 'mobx';

import i18n from '@data/other/i18n';
import { languagesToLocales, defaultLocale } from '@data/static/locales';
import type { Currency, Language } from '@data/types/common';
import { system } from '@config/system';

import type { ImmersiveExperienceSettings } from './types';

export default class Environment {
  crowdinLangCodeMap = {
    cn: 'zh',
    ua: 'uk',
    vn: 'vi',
  };
  @observable currency: Currency = 'usd';
  @observable currencyRate = 1;
  @observable immersiveExperienceSettings?: ImmersiveExperienceSettings;
  @observable language: Language = 'en';
  @observable locale = defaultLocale;

  // Property code don't need to be observable because it's defined once
  propertyCode: string | undefined = undefined;

  constructor() {
    makeObservable(this);
  }

  isHostedExperience = (): this is this & {
    immersiveExperienceSettings: undefined;
  } => system.isHostedExperience && !this.immersiveExperienceSettings;

  isImmersiveExperience = (): this is this & {
    immersiveExperienceSettings: ImmersiveExperienceSettings;
  } => system.isImmersiveExperience && !!this.immersiveExperienceSettings;

  // @TECHDEBT: BENG-1781 - this is a temporary solution for the Immersive Experience MVP.
  // Maybe there's a better place to put this logic and could be redefined as well
  // if we want to use a message API with the ImmExp script to determine this
  // and handle the redirects.
  isInIframe = () => window.self !== window.top;

  @action.bound setCurrency(currency: Currency) {
    this.currency = currency;
  }

  @action.bound setCurrencyRate(currencyRate: number) {
    this.currencyRate = currencyRate;
  }

  @action.bound setImmersiveExperienceSettings = (
    immersiveExperienceSettings: ImmersiveExperienceSettings
  ) => {
    this.immersiveExperienceSettings = immersiveExperienceSettings;
  };

  @action.bound setLanguage(language: Language) {
    document.documentElement.lang = language;
    i18n.changeLanguage(this.crowdinLangCodeMap[language] || language);
    this.language = language;
    this.locale = languagesToLocales[language] || defaultLocale;
  }

  @computed get isLoadingCurrencyRate() {
    return this.currencyRate === 0;
  }

  @action.bound setPropertyCode(code?: string) {
    this.propertyCode = code;
  }
}
