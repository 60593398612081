import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';

import { TRACKING_EVENTS_TYPE, trackEvent } from '@business/utils/gatracking';
import { CartService } from '@data/api/services/cart';
import { Accommodation, Addon } from '@data/models';
import CartTotals from '@data/models/CartTotals';
import ReservationItem, {
  ReservationItemAccommodation,
  ReservationItemAddons,
  ReservationItemModel,
} from '@data/models/ReservationItem';
import { AccommodationData } from '@data/types/AccommodationData';
import { AddonData } from '@data/types/AddonData';

import Store from '../Store';

export default class Cart {
  @observable isLoadingTotals = false;

  @observable items: ReservationItem[] = [];
  @observable totals: CartTotals | null = null;

  constructor() {
    makeObservable(this);
  }

  add(item: ReservationItem) {
    const items: ReservationItem[] = this.items || [];

    // For already purchased items, we don't want to track them
    if (!item.isAlreadyPurchased) {
      const currency = Store.property.model?.defaultCurrency;
      trackEvent({
        data: { currency, item: item.model },
        type: TRACKING_EVENTS_TYPE.ADD_TO_CART,
      });
    }

    let isNew = true;
    this.items = items.map((_item) => {
      if (
        _item.model?.type === 'accommodation' &&
        item.model?.type === 'accommodation' &&
        _item.getLinkingId() === item.getLinkingId()
      ) {
        // shared rooms or private rooms with same amount of adults and kids
        if (
          !_item.model.item.isPrivate ||
          (_item.model.kids === item.model.kids &&
            _item.model.adults === item.model.adults &&
            _item.model.unit === item.model.unit)
        ) {
          isNew = false;
          item.model.amount += _item.model.amount;
          return item;
        }
      }
      return _item;
    });

    if (isNew) {
      this.items.push(item);
    }
  }

  @action.bound addAccommodation(
    accommodation: Accommodation,
    amount: number,
    adults = 1,
    kids = 0,
    unit?: string,
    reservationId: string | undefined = undefined,
    associatedSplitInventoryUnits: string[] = []
  ) {
    if (amount < 1) return;
    this.add(
      new ReservationItem({
        adults,
        amount,
        associatedSplitInventoryUnits,
        item: accommodation,
        kids,
        reservationId,
        type: 'accommodation',
        unit,
      })
    );
  }

  @action.bound addAddon(
    addon: Addon,
    amount = 1,
    reservationId: string | undefined = undefined,
    uuid: string | undefined = undefined,
    accommodationUUID: ReservationItem['id'] | undefined = undefined
  ) {
    if (amount < 1) return;
    this.add(
      new ReservationItem(
        {
          accommodationUUID,
          amount,
          item: addon,
          reservationId,
          type: 'addon',
        },
        uuid
      )
    );
  }

  async calculate() {
    const unpurchasedItems = this.items.filter((x) => !x.isAlreadyPurchased);

    if (unpurchasedItems.length === 0) {
      return;
    }

    if (this.property && this.currency && this.checkIn && this.checkOut) {
      runInAction(() => {
        this.isLoadingTotals = true;
        this.totals = null;
      });

      const accommodations: AccommodationData[] = this.accommodations.map(
        (x) => ({
          adults: x.adults,
          amount: x.amount,
          bookedId: x.item.bookedId,
          kids: x.kids,
          name: x.item.name,
          packageId: x.item.packageId,
          packageName: x.item.packageName,
          rateId: x.item.rateId,
          roomId: x.item.id,
          unit: x.unit,
        })
      );

      const addons: AddonData[] = this.addons.map((x) => ({
        accommodationAdults: x.item.adults,
        accommodationBookedId: x.item.accommodationBookedId,
        accommodationId: x.item.roomId,
        accommodationKids: x.item.kids,
        accommodationPackageId: x.item.packageId,
        addonId: x.item.id,
        amount: x.amount,
        bookedId: x.item.bookedId,
        chargeType: x.item.chargeType,
      }));

      const code = Store.components.searchPanel.value?.code;

      const totals = await CartService.calculate({
        accommodations,
        addons,
        checkIn: this.checkIn,
        checkOut: this.checkOut,
        code,
        currency: this.currency,
        propertyId: this.property.id,
      });

      runInAction(() => {
        this.isLoadingTotals = false;
        this.totals = totals;
      });
    }
  }

  @action.bound clear() {
    this.items = [];
    this.totals = null;
  }

  findAccommodation(
    accommodation: Accommodation,
    unit?: string
  ): ReservationItem | undefined {
    return this.items.find(
      (x) =>
        x.model?.type === 'accommodation' &&
        x.model?.item.id === accommodation.id &&
        (!unit || x.model?.unit === unit)
    );
  }

  findAddonByIdAndAccommodationId(
    addonId: string,
    accommodationUUID: ReservationItem['id']
  ): ReservationItem | undefined {
    return this.items.find((item) =>
      this.matchAddonByIdAndAccomodationId(addonId, item, accommodationUUID)
    );
  }

  findAddonsByAccommodation(
    accommodation: Accommodation | ReservationItemModel
  ): ReservationItem[] {
    let acc: Accommodation | undefined = undefined;
    if (accommodation instanceof Accommodation) {
      acc = accommodation;
    } else if (accommodation.type === 'accommodation') {
      acc = accommodation.item;
    }
    return this.items.filter(
      (x) =>
        x.model &&
        x.model.type === 'addon' &&
        x.model.item.roomId === acc?.id &&
        x.model.item.packageId === acc?.packageId
    );
  }

  findAddonsByAccommodationId(
    accommodationUUID: ReservationItem['id']
  ): ReservationItem[] {
    return this.items.filter(
      (item) =>
        item.model?.type === 'addon' &&
        accommodationUUID === item.model.accommodationUUID
    );
  }

  findAddonsForRoom(reservationItem: ReservationItem): ReservationItem[] {
    return this.items.filter(
      (item) =>
        item.model?.type == 'addon' &&
        reservationItem.id === item.model.accommodationUUID
    );
  }

  findAllAccomodations(accommodation: Accommodation): ReservationItem[] {
    return this.items.filter(
      (x) =>
        x.model?.type === 'accommodation' &&
        x.model?.item.id === accommodation.id
    );
  }

  findPossibleAddonsByAccommodation(
    accommodation: Accommodation | ReservationItemModel
  ): Addon[] {
    let acc: Accommodation | undefined = undefined;
    if (accommodation instanceof Accommodation) {
      acc = accommodation;
    } else if (accommodation.type === 'accommodation') {
      acc = accommodation.item;
    }
    return (
      Store.property.addons.model?.items.filter(
        (x) => x.roomId == acc?.id && x.packageId == acc?.packageId
      ) || []
    );
  }

  findRoomForAddon(reservationItem: ReservationItem): ReservationItem {
    return this.getAssociatedReservationItems(
      reservationItem,
      'accommodation'
    )[0];
  }

  getAddonsForEachAccommodation(): Record<
    ReservationItem['id'],
    ReservationItemAddons[]
  > {
    const addonsByAccommodationId = this.items
      .filter((x) => x.model?.type === 'accommodation')
      .reduce((dict, { id }) => {
        dict[id] = [];
        return dict;
      }, {});

    this.items
      .filter((x) => x.model?.type === 'addon')
      .map((x) => x.model as ReservationItemAddons)
      .forEach((addon: ReservationItemAddons) => {
        addonsByAccommodationId[addon.accommodationUUID || ''].push(addon);
      });

    return addonsByAccommodationId;
  }

  getAssociatedReservationItems(
    reservationItem: ReservationItem,
    searchType: 'addon' | 'accommodation'
  ): ReservationItem[] {
    return this.items.filter(
      (item) =>
        item.model?.type == searchType &&
        item.getLinkingId() == reservationItem.getLinkingId()
    );
  }

  matchAddonByIdAndAccomodationId(
    addonId: string,
    item: ReservationItem,
    accommodationUUID: ReservationItem['id'] | undefined
  ) {
    return (
      item.model?.type == 'addon' &&
      addonId === item.getItemId() &&
      accommodationUUID === item.model.accommodationUUID
    );
  }

  @action.bound remove(itemToRemove?: ReservationItem) {
    if (!itemToRemove) return;
    this.items = [...this.items].filter((x) => x.id !== itemToRemove.id);
    const currency = Store.property.model?.defaultCurrency;

    trackEvent({
      data: { currency, item: itemToRemove.model },
      type: TRACKING_EVENTS_TYPE.REMOVE_FROM_CART,
    });

    if (itemToRemove.model?.type === 'accommodation') {
      this.findAddonsForRoom(itemToRemove).forEach((addonItem) => {
        this.remove(addonItem);
      });
    }
  }

  @action.bound removeAccommodation(
    accommodation: Accommodation,
    unit?: string
  ) {
    this.remove(this.findAccommodation(accommodation, unit));
  }

  @action.bound removeAddon(
    item: Addon,
    accommodationUUID: ReservationItem['id']
  ) {
    const list = this.items.filter((x) => {
      if (
        !x.model ||
        x.model.type !== 'addon' ||
        x.model.item.bookedId.length > 0
      ) {
        return false;
      }

      if (
        item.chargeType === 'per-night' ||
        item.chargeType === 'per-reservation'
      ) {
        return x.getItemId() === item.id;
      }

      return (
        x.getItemId() === item.id &&
        x.model.accommodationUUID === accommodationUUID
      );
    });

    for (let i = 0; i < list.length; i++) {
      this.remove(list[i]);
    }
  }

  @action.bound removeByCartId(id: string) {
    this.remove(this.items.find((x) => x.id == id));
  }

  update(
    addon: Addon,
    amount: number,
    accommodationUUID: ReservationItem['id'] | undefined
  ) {
    this.items = [...this.items].map((item) => {
      if (
        this.matchAddonByIdAndAccomodationId(
          addon.id,
          item,
          accommodationUUID
        ) &&
        item.model
      ) {
        item.model.amount = amount;
      }
      return item;
    });
  }

  @action.bound updateAddon(
    addon: Addon,
    amount = 1,
    accommodationUUID: ReservationItem['id'] | undefined
  ) {
    if (amount < 1) return;
    this.update(addon, amount, accommodationUUID);
  }

  async updateTotals(data_res: string) {
    if (this.property && this.addons.length > 0) {
      const addons: AddonData[] = [];
      for (let i = 0; i < this.addons.length; i++) {
        const addon = this.addons[i];
        // skip already purchased addons
        if (addon.item.bookedId.length > 0) continue;
        const accommodation = this.accommodations.find(
          (x) =>
            x.item.id == addon.item.roomId &&
            x.item.packageId === addon.item.packageId &&
            x.adults === addon.item.adults &&
            x.kids === addon.item.kids
        );
        if (accommodation) {
          const accommodationBookedId = addon.item.accommodationBookedId;

          addons.push({
            accommodationBookedId,
            accommodationId: accommodation.item.id,
            addonId: addon.item.id,
            amount: addon.amount,
            bookedId: [],
          });
        }
      }

      await CartService.updateTotals({
        addons,
        cartItems: this.items,
        data_res,
        propertyId: this.property.id,
      });
    }
  }

  @computed get accommodations(): ReservationItemAccommodation[] {
    return this.items
      .filter((x) => x.model?.type === 'accommodation')
      .map((x) => x.model as ReservationItemAccommodation);
  }

  @computed get addons(): ReservationItemAddons[] {
    return this.items
      .filter((x) => x.model?.type === 'addon')
      .map((x) => x.model as ReservationItemAddons);
  }

  @computed private get property() {
    return Store.property.model;
  }

  @computed private get currency() {
    return Store.environment.currency;
  }

  @computed get numberOfNights(): number | undefined {
    if (this.accommodations.length > 0) {
      return this.accommodations[0].item.nights;
    } else {
      return undefined;
    }
  }

  @computed get checkIn() {
    return Store.components.searchPanel.value.checkin;
  }

  @computed get checkOut() {
    return Store.components.searchPanel.value.checkout;
  }

  @computed get isLoading() {
    return (
      Store.property.addons.isFetching ||
      Store.property.reservation.isFetching ||
      (!Store.property.model?.settings.requireAcceptTerms &&
        Store.property.reservation.isProcessing)
    );
  }

  @computed get reservation() {
    return Store.property.reservation.model;
  }

  // Ids of split inventory for accommodations already added to the cart - should not be added again
  @computed get prohibitedSplitInventory() {
    const splitInvs: string[] = [];
    this.accommodations.forEach((accommodation) => {
      splitInvs.push(...accommodation.associatedSplitInventoryUnits);
    });
    return splitInvs;
  }
}
