export enum FeatureFlag {
  useStaticMobileShoppingCart = 'feature.booking-engine.use-static-mobile-shopping-cart',
  usePaymentSDK = 'use-payment-sdk',
  usePaymentSDKOnAnyGateway = 'use-payment-sdk-on-any-gateway',
  usePaymentSDKPaypalOnlyButton = 'use-payment-sdk-paypal-only-button',
  ga4Changes = 'feature.booking-engine.ga4-threeandsix-changes',
  enablePixel = 'feature.booking-engine.enable-pixel',
  restrictSplitInventory = 'feature.booking-engine.restrict-split-inventory',
  sendXPropertyCode = 'send-x-property-code',
}
