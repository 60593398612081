import { computed, makeObservable, observable, runInAction } from 'mobx';

import {
  Accommodation,
  AccommodationList as AccommodationListModel,
  AccommodationTypeList as AccommodationTypeListModel,
} from '@data/models';
import { AccommodationsService, AccommodationsTypeService } from '@data/api';
import TimeUtils from '@business/utils/time';
import { isAllotmentBlockCode } from '@business/utils/promocodes';
import { eventSystem } from '@business/event-system';
import { DateString } from '@data/api/types/native';

import Store from '../Store';

type AccommodationSearchOptions = {
  adults?: number;
  checkin?: Date | string;
  checkout?: Date | string;
  kids?: number;
  ratePlans?: string;
  roomIds?: string;
  roomTypes?: string;
};

export default class Accommodations {
  @observable checkin: string | null = null;
  @observable checkout: string | null = null;
  @observable isLoading = true;
  @observable model: AccommodationListModel;
  @observable types: AccommodationTypeListModel | null = null;

  constructor() {
    this.model = new AccommodationListModel();
    makeObservable(this);
  }

  loadAccommodationTypes = async (propertyId?: number) => {
    if (!propertyId) return;

    // passing a previous dates to get all types
    const result = await AccommodationsTypeService.load(propertyId);
    runInAction(() => {
      this.types = result;
    });

    return result;
  };

  search = async ({
    checkin,
    checkout,
    roomTypes,
    ratePlans,
    adults,
    kids,
    roomIds,
  }: AccommodationSearchOptions) => {
    if (!(this.propertyId && checkin && checkout)) return;
    runInAction(() => {
      this.isLoading = true;
    });

    const _checkin = checkin
      ? TimeUtils.format(checkin)
      : TimeUtils.format(TimeUtils.getDateOffset(0));
    const _checkout = checkout
      ? TimeUtils.format(checkout)
      : TimeUtils.format(TimeUtils.getDateOffset(1));

    const code = Store.components.searchPanel.value?.code;
    const promoCode = {};
    if (code && code.length > 0) {
      if (isAllotmentBlockCode(code)) {
        promoCode['allotment_block_code'] = code;
      } else {
        promoCode['promo_code'] = code;
      }
    }

    eventSystem.emit('internal/rooms-availability-requested', {
      checkin: _checkin as DateString,
      checkout: _checkout as DateString,
      code,
    });

    const result = await AccommodationsService.search({
      adults,
      checkin: _checkin,
      checkout: _checkout,
      currency_code: this.currency,
      kids,
      lang: this.language,
      rate_plan: ratePlans,
      room_ids: roomIds,
      room_type: roomTypes,
      widget_property: this.propertyId,
      ...promoCode,
    });

    let lowestPriceAccommodation: Accommodation | undefined;

    if (result?.items.length) {
      lowestPriceAccommodation = result?.items.reduce((acc, item) => {
        return acc.rate < item.rate ? acc : item;
      });
    }

    eventSystem.emit(
      'availability-searched',
      {
        bestPrice: lowestPriceAccommodation?.rate || 0,
        bestPriceRoomName: lowestPriceAccommodation?.name || '',
        checkin: _checkin as DateString,
        checkout: _checkout as DateString,
        currencyCode: this.currency,
        numOfAdults: adults,
        numOfChildren: kids,
        numOfNights: TimeUtils.getDiff(
          _checkout as DateString,
          _checkin as DateString
        ),
        rateId: lowestPriceAccommodation?.rateId || '',
        roomId: lowestPriceAccommodation?.id || '',
      },
      { visibility: 'external' }
    );

    runInAction(() => {
      this.checkin = _checkin;
      this.checkout = _checkout;
      this.model = result;
      this.isLoading = false;
    });
    return result;
  };

  @computed private get language() {
    return Store.environment.language || 'en';
  }

  @computed private get currency() {
    return Store.environment.currency || 'usd';
  }

  @computed private get propertyId() {
    return Store.property.model?.id;
  }
}
