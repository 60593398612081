import { computed, makeObservable, observable, runInAction } from 'mobx';

import { PropertyService } from '@data/api';
import { GetPropertyRequest } from '@data/api/services/property';
import { Property as PropertyModel } from '@data/models';
import Store from '@data/stores/Store';
import { Amenity } from '@data/types/common';

import Accommodations from '../Accommodations';
import Addons from '../Addons';
import Amplify from '../Amplify';
import AvailabilityCalendar from '../AvailabilityCalendar';
import Cart from '../Cart';
import CustomPaymentOption from '../CustomPaymentOption';
import Guests from '../Guests';
import Payment from '../Payment';
import Reservation from '../Reservation';

export default class Property {
  accommodations = new Accommodations();
  addons = new Addons();
  amplify = new Amplify();
  availabilityCalendar = new AvailabilityCalendar();
  cart = new Cart();
  customPaymentOption?: CustomPaymentOption;
  guests = new Guests();
  @observable model: PropertyModel | null = null;
  payment = new Payment();
  reservation = new Reservation();

  constructor() {
    makeObservable(this);
  }

  get = async (request: GetPropertyRequest) => {
    runInAction(() => {
      this.model = null;
    });

    const property = await PropertyService.getProperty(request);

    runInAction(() => {
      this.model = property;
    });

    return property;
  };

  @computed private get language() {
    return Store.environment.language;
  }

  @computed get propertyFeatures() {
    return this.model?.amenities.map(
      ({ lang, name, slug }) =>
        ({
          name: lang?.getText(this.language) || name,
          slug,
        } as Amenity)
    );
  }

  @computed get propertyAdditionalFeatures() {
    return this.model?.additionalAmenities.map(
      ({ name }) =>
        ({
          name,
        } as Amenity)
    );
  }

  @computed get description() {
    return this.model?.description.getText(this.language);
  }

  @computed get termsAndConditions() {
    return this.model?.termsAndConditions.getText(this.language);
  }

  setCustomPaymentOption = (customPaymentOption: CustomPaymentOption) =>
    runInAction(() => {
      this.customPaymentOption = new CustomPaymentOption(customPaymentOption);
    });
}
