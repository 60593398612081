import { makeObservable, observable, runInAction } from 'mobx';

import { AddonList as AddonListModel } from '@data/models';
import { AddonsService, AddonsSearchParams } from '@data/api/services/addons';

export default class Addons {
  @observable isFetching = false;

  @observable model: AddonListModel | null = null;

  constructor() {
    makeObservable(this);
  }

  search = async ({
    accommodations,
    checkin,
    checkout,
    language,
    propertyId,
  }: AddonsSearchParams) => {
    runInAction(() => {
      this.model = null;
    });

    runInAction(() => {
      this.isFetching = true;
    });

    const result = !(propertyId && checkin && checkout)
      ? null
      : await AddonsService.search({
          accommodations,
          checkin,
          checkout,
          language,
          propertyId,
        });

    runInAction(() => {
      this.isFetching = false;
      this.model = result;
    });

    return result;
  };
}
