import { makeAutoObservable } from 'mobx';

import { CARD_TYPES, SupportedCreditCard } from '@business/utils/creditcard';
import { parseRawBoolean } from '@data/api/helpers';
import { AmplifyFeature } from '@data/api/types/amplify/features';
import { RawProperty } from '@data/api/types/property';
import type { Currency, Language } from '@data/types/common';
import { AmenityKeys } from '@molecules/Amenity/icons.types';
import {
  PaymentGateway,
  PaymentGatewayField,
} from '@data/models/payment-gateway';

import AccommodationSourceSettings from './AccommodationSourceSettings';
import Image from './Image';
import MultilangText from './MultilangText';
import Settings from './Settings';

function getLogoUrl({
  booking_display_photos,
  booking_logo,
  logo_original,
}: RawProperty) {
  if (parseRawBoolean(booking_display_photos)) {
    return logo_original;
  } else if (booking_logo) {
    return booking_logo?.path;
  } else {
    return null;
  }
}

function formatTime(time24?: string, timeFormat?: string) {
  if (!time24) {
    return '';
  }
  const timeParts = time24.split(':');

  const useMilitaryTime = timeFormat === 'H:i';

  if (useMilitaryTime || timeParts.length !== 2) {
    return time24;
  }
  let hours = parseInt(timeParts[0], 10);
  const minutes = String(parseInt(timeParts[1], 10)).padStart(2, '0');
  const amOrPm = hours >= 12 ? 'PM' : 'AM';
  if (hours >= 13) {
    hours = hours - 12;
  }
  if (hours === 0) {
    hours = 12;
  }
  return `${hours}:${minutes} ${amOrPm}`;
}

function parsePaymentGatewayFields(
  paymentGatewayData: RawProperty['payment_gateway_settings']
): PaymentGatewayField[] {
  if (!paymentGatewayData) {
    return [];
  }
  const fields: PaymentGatewayField[] = [];
  const keys = Object.keys(paymentGatewayData.billing_address_fields);
  const configurationKeys = Object.keys(
    paymentGatewayData.fields_property_configuration
  );
  keys.forEach((name) => {
    const billingAddressField = paymentGatewayData.billing_address_fields[name];
    const label = billingAddressField.label;
    const display = configurationKeys.includes(name + '_display');
    const required = configurationKeys.includes(name + '_mandatory');
    const field: PaymentGatewayField = {
      label,
      name,
      required,
    };

    if (billingAddressField.countryBasedOptions) {
      const contryBasedKeys = Object.keys(
        billingAddressField.countryBasedOptions
      );
      field.options = Object.entries(
        billingAddressField.countryBasedOptions[contryBasedKeys[0]]
      ).map(([value, label]) => ({ label, value }));
    }

    if (display) {
      fields.push(field);
    }
  });

  return fields;
}

export default class Property {
  additionalAmenities!: {
    name: string;
  }[];
  address!: {
    address1: string;
    address2: string;
    city: string;
    country: string;
    lat: string;
    latitude?: string;
    lng: string;
    longitude?: string;
    state: string;
    zip: string;
  };
  allowSameDayReservation!: boolean;
  amenities!: {
    lang?: MultilangText;
    name: string;
    slug: AmenityKeys;
  }[];
  amplifyFeatures!: AmplifyFeature[];
  bankTransferInstructions!: MultilangText;
  cancellationPolicies!: {
    customPolicy: MultilangText;
    standardizedPolicies: {
      fullCharge: {
        allowed: boolean;
        chargeType: 'full_stay' | 'full_deposit' | null;
        daysBeforeArrival: number | null;
      };
      noCharge: {
        allowed: boolean;
        daysBeforeArrival: number | null;
      };
      partialCharge: {
        allowed: boolean;
        chargeAmount: number | null;
        chargeType:
          | 'first_night_stay'
          | 'full_stay_perc'
          | 'deposit_perc'
          | null;
        daysBeforeArrival: number | null;
      };
    };
    useCustomPolicy: boolean;
  };
  cancellationPolicy!: MultilangText;
  code!: string;
  colors!: {
    background: string;
    primary: string;
  };
  contact!: {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
  };
  coverImage!: Image;
  customFields!: {
    label: MultilangText;
    max_length: number;
    name: string;
    required: boolean;
    section: string;
    type: string;
  }[];
  customFooter!: string;
  customHeader!: string;
  defaultCurrency!: Currency;
  defaultLanguage!: Language;
  deposit!: {
    depositType: 'day' | 'percent' | 'fixed' | 'no_deposit';
    noDepositCCCollect: boolean;
    skipPayment: boolean;
  };
  description!: MultilangText;
  facebookPixelId!: string | null;
  formats!: {
    decimalPoint: string;
    thousandsSeparator: string;
  };
  id!: number;
  images!: Image[];
  name!: string;
  noAvailabilityText?: MultilangText;
  notFound = false;
  paymentAPIKey?: string;
  paymentGateway!: PaymentGateway;
  paymentMethods!: {
    bankTransfersEnabled: boolean;
    creditCardsEnabled: boolean;
    paypalEnabled: boolean;
    supportedCards: SupportedCreditCard[];
  };
  picture!: Image;
  policies!: {
    checkin: string;
    checkout: string;
    lateCheckout: boolean;
    lateCheckoutHour: string;
    lateCheckoutType: string;
    lateCheckoutVal: number;
  };
  roomsSourceSettings!: AccommodationSourceSettings[];
  settings!: Settings;
  termsAndConditions!: MultilangText;
  usePaymentSDK!: boolean;

  constructor(serverData?: { data: RawProperty; success?: boolean }) {
    makeAutoObservable(this);

    if (!serverData || !serverData.success || !serverData.data) {
      this.id = 0;
      this.notFound = true;

      return;
    }

    const { data } = serverData;

    this.id = parseInt(data.property_id);
    this.code = data.property_code;
    this.name = data.hotel_name;

    this.picture = new Image({
      alt: data.hotel_name,
      src: getLogoUrl(data),
    });
    this.coverImage = new Image({
      alt: data.hotel_name,
      src: data.logo_original,
      thumb: data.logo,
    });
    this.description = new MultilangText(data.hotel_about_langs);
    this.address = data.hotel_address;
    this.images = (data.additional_photos || []).map(
      (image) =>
        new Image({ alt: image.alt, src: image.path, thumb: image.thumb_path })
    );
    this.cancellationPolicy = new MultilangText(
      data.custom_cancellation_policy_langs
    );
    this.termsAndConditions = new MultilangText(data.terms_txt_langs);
    this.allowSameDayReservation = parseRawBoolean(data.same_day_reservation);

    this.bankTransferInstructions = new MultilangText(
      data.bank_transfer_instruction
    );

    this.customHeader = data?.custom?.header || '';
    this.customFooter = data?.custom?.footer || '';

    // colors
    this.colors = {
      background: data.custom?.colors?.background || '#F6F5F5',
      primary: data.custom?.colors?.searchButton || '#6A5ACD',
    };

    if (
      data.custom?.no_availability_text &&
      Object.keys(data.custom?.no_availability_text).length > 0
    ) {
      this.noAvailabilityText = new MultilangText(
        data.custom?.no_availability_text
      );
    }

    this.paymentAPIKey = data.payment_hash;

    this.facebookPixelId = data.facebook_pixel_id || null;

    if (
      !data.payment_gateway_settings ||
      data.payment_gateway_settings.gateway_connected === ''
    ) {
      this.paymentGateway = {
        fields: [],
        name: data.payment_gateway as string,
      };
    } else {
      this.paymentGateway = {
        fields: parsePaymentGatewayFields(data.payment_gateway_settings),
        name: data.payment_gateway_settings.gateway_connected,
      };
    }

    this.usePaymentSDK = data.use_payment_sdk;

    const creditCardsEnabled = data.credit_cards_enabled || false;
    const showCreditCardsInFrontend = parseRawBoolean(
      data.cards_frontend_enabled
    );

    this.paymentMethods = {
      bankTransfersEnabled: data.bank_transfers_enabled || false,
      creditCardsEnabled: creditCardsEnabled && showCreditCardsInFrontend,
      paypalEnabled: data.paypal_enabled || false,
      supportedCards: data.payments_credit_cards.map(
        ({ payment_code, payment_name }) => ({
          code: payment_code as CARD_TYPES,
          name: payment_name,
        })
      ),
    };

    this.defaultCurrency =
      (data.default_currency?.toLowerCase() as Currency) || 'usd';
    this.defaultLanguage =
      (data.default_lang?.toLowerCase() as Language) || 'en';

    this.cancellationPolicies = {
      customPolicy: new MultilangText(data.custom_cancellation_policy_langs),
      standardizedPolicies: {
        fullCharge: {
          allowed: parseRawBoolean(data.terms_full_charge_allowed),
          chargeType: data.terms_full_charge_type,
          daysBeforeArrival: data.terms_full_charge_cancellation_days,
        },
        noCharge: {
          allowed: parseRawBoolean(data.terms_no_charge_allowed),
          daysBeforeArrival: data.terms_no_charge_cancellation_days,
        },
        partialCharge: {
          allowed: parseRawBoolean(data.terms_partial_charge_allowed),
          chargeAmount: data.terms_partial_amount,
          chargeType: data.terms_partial_charge_type,
          daysBeforeArrival: data.terms_partial_charge_cancellation_days,
        },
      },
      useCustomPolicy: data.is_own_cancellation === '1',
    };

    this.customFields = [];

    const customFields = data.custom_fields || {};

    Object.keys(customFields).forEach((key) => {
      const field = customFields[key];

      if (field?.location?.includes('booking')) {
        this.customFields.push({
          label: new MultilangText(field.label_langs),
          max_length: parseInt(field.max_len),
          name: key,
          required: parseRawBoolean(field.required),
          section: field.section,
          type: field.type,
        });
      }
    });

    this.amenities = Object.values(data.hotel_features).map(
      ({ name, slug, lang }) => ({
        lang: new MultilangText(lang),
        name,
        slug: slug as AmenityKeys,
      })
    );

    this.additionalAmenities = Object.keys(data.hotel_features_custom).map(
      (name: string) => ({
        name,
      })
    );

    this.contact = {
      email: data.hotel_email,
      firstName: data.contact_first_name,
      lastName: data.contact_last_name,
      phone: data.hotel_phone,
    };

    this.policies = {
      checkin: formatTime(data.terms_normal_checkin, data.formats?.time_format),
      checkout: formatTime(
        data.terms_normal_checkout,
        data.formats?.time_format
      ),
      lateCheckout: parseRawBoolean(data.terms_late_checkout),
      lateCheckoutHour: formatTime(
        data.terms_late_checkout_hour,
        data.formats?.time_format
      ),
      lateCheckoutType: data.terms_late_checkout_type,
      lateCheckoutVal: parseFloat(data.terms_late_checkout_val),
    };

    this.settings = new Settings(data);

    this.deposit = {
      depositType: data.terms_deposit_type,
      noDepositCCCollect: parseRawBoolean(data.no_deposit_cc_collect),
      skipPayment:
        data.terms_deposit_type === 'no_deposit' &&
        !parseRawBoolean(data.no_deposit_cc_collect),
    };

    this.formats = {
      decimalPoint:
        data.hotel_decimal_separator === undefined
          ? '.'
          : data.hotel_decimal_separator,
      thousandsSeparator:
        data.hotel_thousand_separator === undefined
          ? ','
          : data.hotel_thousand_separator,
    };

    this.roomsSourceSettings = [];
    if (data.settings?.roomTypeBookingSettings) {
      for (let i = 0; i < data.settings?.roomTypeBookingSettings.length; i++) {
        const setting = data.settings?.roomTypeBookingSettings[i];
        this.roomsSourceSettings.push({
          bookableLimit: parseInt(setting.bookableLimit),
          canSelectSpecificRoom: parseRawBoolean(setting.bookRoomsOnMyBookings),
          capacity: parseInt(setting.capacity),
          maxRooms: parseInt(setting.maxRooms),
          roomTypeId: setting.roomTypeId,
          title: setting.title,
        });
      }
    }

    this.amplifyFeatures = data.amplify_features || [];
  }
}
