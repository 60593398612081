import bind from 'bind-decorator';
import { configure } from 'mobx';

import { setupQueryCache } from '@data/api/queryClient';
import { asyncSuspense } from '@data/other/asyncSuspense';

import Components from './Components';
import Environment from './Environment';
import Property from './Property';
import Session from './Session';

configure({ isolateGlobalState: true });

export class Store {
  components = new Components();
  environment = new Environment();
  property = new Property();
  session = new Session();

  @bind async init() {
    await setupQueryCache();
    return this;
  }
}

const store = new Store();

export const initializeStore = asyncSuspense(store.init, 'store');

// Used by the integration tests to reset the store before mounting the App
export const resetStore = () => {
  store.components = new Components();
  store.environment = new Environment();
  store.property = new Property();
  store.session = new Session();
};

export interface StoreProps {
  store: Store;
}

export default store;
